<template>
	<div id="contactos" v-if="dataReady">

		<div class="page-header container-fluid text-center d-flex align-items-center justify-content-center bg-contactos">
			<div class="page-header-content">
				<h1 class="text-line-1 mb10" v-html="this.firstWordBold(pageinfo.title)"></h1>
				<h2 class="text-line-2">
					{{ pageinfo.subtitle }}
				</h2>
			</div>
		</div>

		<div class="container mt50 mb200">
			<div class="row">

				<div class="col-12 col-md-4 lista-contactos text-center text-md-left">
					<h5 class="mt5">ENDEREÇO</h5>
					<p>
						{{ pageinfo.morada }}<br>
						{{ pageinfo.localidade }}<br>
						{{ pageinfo.codpostal }}
					</p>
					<a :href="pageinfo.maplink" 
						class="btn btn-outline-primary mb60"
						target="_blank">
							Link Google Maps
					</a>

					<h5>TELEFONE</h5>
					<p class="mb60">
						<a :href="'tel:'+removeSpaces(pageinfo.telefone)">
							{{ pageinfo.telefone }}
						</a><br>
						Chamada para a rede fixa nacional
					</p>

					<h5>EMAIL</h5>
					<p class="mb60">
						<a :href="'mailto:'+pageinfo.email+'?subject=Contacto RCF Estores'" target="_blank">
							{{ pageinfo.email }}
						</a>
					</p>

					<h5>HORÁRIO</h5>
					<p class="mb60" v-html="convertLineBreaks(pageinfo.horario)"></p>
				</div>


				<div class="col-12 col-md-7 offset-md-1">

					<h3>Pedido de orçamento</h3>
					<p class="form-intro-text">Envie-nos o seu pedido de orçamento tão detalhado quanto possível para que consigamos elaborar um orçamento à medida das suas necessidades.</p>
					
					<form id="contactForm"><!--@submit="checkForm"-->

						<div class="form-group">
							<label for="fNome">Nome</label>
							<input type="text" class="form-control" id="fNome" v-model="form.nome">
						</div>

						<div class="form-group">
							<label for="fEmail1">Email</label>
							<input type="email" class="form-control" id="fEmail1" v-model="form.email">
						</div>

						<div class="form-group">
							<label for="fTelefone">Telefone</label>
							<input type="number" class="form-control" id="fTelefone" v-model="form.telefone">
						</div>

						<div class="form-group">
							<label for="fMorada">Morada</label>
							<input type="text" class="form-control" id="fMorada" v-model="form.morada">
						</div>


						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="fCodPostal">Código Postal</label>
								<input type="text" class="form-control" id="fCodPostal" v-model="form.codpostal">
							</div>
							<div class="form-group col-md-6">
								<label for="fLocalidade">Localidade</label>
								<input type="text" class="form-control" id="fLocalidade" v-model="form.localidade">
							</div>
						</div>

						<div class="form-group">
							<label for="fTexto">Descreva o que pretende orçamentar</label>
							<textarea class="form-control" id="fTexto" rows="5" v-model="form.texto"></textarea>
						</div>

						<p v-if="errors.length">
							<b>Por favor corrija os seguintes erros:</b>
							<ul>
								<li v-for="error in errors" :key="error">{{ error }}</li>
							</ul>
						</p>

						<p v-if="sendfail" class="alert alert-danger">
							Ocorreu um erro ao enviar, por favor tente de novo!
						</p>
						
						<p v-if="sent" class="alert alert-success mt30">
							Obrigado pelo seu contacto, iremos responder tão breve quanto possível.
						</p>

						<a v-if="!sent" @click="checkForm" class="btn btn-primary">Enviar</a>

					</form>

				</div> 

			</div>
		</div>

		<MainFooter />

	</div>
</template>

<script>
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import axios from "axios";

export default {
	name: "Contactos",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			pageinfo: null,
			errors: [],
			form: {
				nome: null,
				email: null,
				telefone: null,
				morada: null,
				codpostal: null,
				localidade: null,
				texto: null
			},
			sendfail: false,
			sent: false
		};
	},
	mounted () {
		axios
			.get(globals.COCKPIT_SERVER+'singletons/get/contactos'+globals.COCKPIT_TOKEN)
			.then(response => {
				this.pageinfo = response.data
				this.dataReady = true
				this.changeMetaData()
			})
	},
	methods: {
		firstWordBold(content) {
			var text = content.trim().split(" ");
			var first = text.shift();
			return "<b>"+first+"</b> " + text.join(" ");
		},
		changeMetaData() {
			this.$parent.metaTitle = 'Contactos';
			this.$parent.metaDescription = 'Diga-nos o que pretende realizar, e iremos aconselhar a melhor solução para si.';
		},
		checkForm() {


			if (this.form.nome && this.form.email && this.form.texto) {
				
				// BUILD JSON ARRAY FOR COCKPIT CMS
				let senddata = JSON.stringify({
					form: {
						nome: this.form.nome,
						email: this.form.email,
						telefone: this.form.telefone,
						morada: this.form.morada,
						codpostal: this.form.codpostal,
						localidade: this.form.localidade,
						texto: this.form.texto
					}
				})

				this.$http.post(globals.COCKPIT_SERVER+'forms/submit/orcamento'+ globals.COCKPIT_TOKEN, senddata)
					.then(function(response) {
						// success
						this.sendfail = false;
						this.sent = true;
						console.log("Form enviada! "+response);
					}, function(response) {
						// error
						this.sendfail = true;
						console.log("Erro ao enviar Form: "+response);
					});

				console.dir(senddata);
			}

			this.errors = [];

			if (!this.form.nome) {
				this.errors.push('Por favor indique o seu nome.');
			}
			if (!this.form.email) {
				this.errors.push('Por favor indique o seu email.');
			}
			if (!this.form.texto) {
				this.errors.push('Por favor indique o que pretende orçamentar.');
			}

		}
	}
};
</script>